






















































































































































































































































































































































































import { Component } from "vue-property-decorator";
import { Collection, ProductLineName } from "@/models/products/collection";
import ProductService from "@/services/product_service";
import ConfigFileService from "@/services/config_file_service";
import { ProductType } from "@/models/products/product";
import { UmbrellaModel, UmbrellaType } from "@/models/products/umbrella";
import { mixins } from "vue-class-component";
import {
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
} from "@/mixins/store";
import { ConfigCreator } from "@/mixins/configurator";
import { APIError, NotFoundError, ViewerError } from "@/services/error_service";
import { ConfigFile } from "@/models/configurator/config_file";
import { Auth } from "@/mixins/auth";
import AuthService from "@/services/auth_service";

@Component
export default class CollectionMenu extends mixins(
  BaseStore,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  FrameStore,
  ConfigCreator,
  Auth
) {
  protected selection = {} as Collection;
  protected type: UmbrellaType = UmbrellaType.Market;
  protected UmbrellaType = UmbrellaType;
  protected umbrellas: Collection[] = [];
  protected productService = new ProductService();
  protected configFileService = new ConfigFileService();
  protected authService = new AuthService();
  protected ProductLineName = ProductLineName;
  async created() {
    this.umbrellas = await this.getProductLines();
    this.getDefaultSelection();
    this.checkAuth();
  }

  protected async checkAuth(): Promise<void> {
    try {
      const user: any = await this.authService.checkAuth();
      this.setLoggedIn(true);
      this.setUserRole(user.role);
    } catch (err) {
      this.setLoggedIn(false);
      this.setUserRole(null);
    }
  }

  /** Fix for weird vue transition/animation issues */
  protected beforeLeave(el: any): void {
    const { marginLeft, marginTop, width, height } =
      window.getComputedStyle(el);
    el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`;
    el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`;
    el.style.width = width;
    el.style.height = height;
  }

  protected async getProductLines(): Promise<Collection[]> {
    let umbrellas: Collection[] = [];
    try {
      const res = await this.productService.getProducts({
        category: ProductType.Umbrella,
        sort: "productline",
      });
      umbrellas = res;
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return umbrellas;
  }

  protected async start(): Promise<void> {
    const loader = this.$loading.show(
      { opacity: 1 },
      {
        before: this.$createElement("h1", {
          domProps: { innerHTML: "Loading your umbrella..." },
        }),
      }
    );
    setTimeout(() => {
      loader.hide();
    }, 2000);
    try{
    const configFile = this.getDefaultConfigFile();
    await this.transformConfigFileAndSaveToStore(configFile);
    this.$router.push("/designer");
    } catch(err) {
              APIError.redirect(
          "There was an error loading the umbrella configuration. The selected umbrella and/or its parts or fabric may no longer be available. If you believe this is an error, please contact Frankford Umbrellas directly for more information at info@frankfordumbrellas.com."
        );
    }
  }

  protected getDefaultConfigFile(): ConfigFile {
    if (this.selection.config_file) {
      const file: any = this.selection.config_file as any;
      return file[0]; //TODO, THIS SHOULDNT BE AN ARRAY
    } else {
      throw new ViewerError(
        "No default configuration for this umbrella found."
      );
    }
  }

  protected selectModel(umbrella: Collection): void {
    this.selection = umbrella;
  }

  protected selectTab(type: UmbrellaType): void {
    this.type = type;
    this.getDefaultSelection();
  }

  protected getDefaultSelection(): void {
    const defaultSelection = this.umbrellas.filter(
      (umbrella) => umbrella.umbrella_type === this.type
    );
    if (this.type === UmbrellaType.Market) {
      this.selection = defaultSelection.find(
        (e) => e.handle === "monterey_auto_tilt"
      )!;
    } else if (this.type === UmbrellaType.Cantilever) {
      this.selection = defaultSelection.find((e) => e.handle === "eclipse")!;
    } else if (this.type === UmbrellaType.Giant) {
      this.selection = defaultSelection.find((e) => e.handle === "monterey_g_series")!;
    } else if (this.type === UmbrellaType.Patio) {
      this.selection = defaultSelection.find((e) => e.handle === "laurel")!;
    } else if (this.type === UmbrellaType.Beach) {
      this.selection = defaultSelection.find((e) => e.handle === "avalon")!;
    }
  }

  protected get umbrellasFiltered() {
    return this.umbrellas.filter(
      (umbrella) => umbrella.umbrella_type === this.type && umbrella.handle !== "shade_star"
    );
  }

  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }

  protected getImg(umbrella: UmbrellaModel): string {
    let img = "";
 
      img = umbrella.image as string;
    
    return img + "?width=285";
  }
}
